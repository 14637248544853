import React from 'react';
import { navigate } from 'gatsby';

const Page404 = () => {
  React.useEffect(() => {
    navigate('/');
  }, []);
  return null;
}

export default Page404;